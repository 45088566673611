import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';
import applicationJson from '../../../.application.json';

export type ISettingsParams = {
  greetingsText: SettingsParamType.Text;
  additionalInfo: SettingsParamType.Text;
  subTitleText: SettingsParamType.Text;
};

export default createSettingsParams<ISettingsParams>({
  greetingsText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('app.settings.defaults.greetingsPretext')} ${
        applicationJson.appName
      }`,
  },
  additionalInfo: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
  subTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => 'Sub Title',
    inheritFromAppScope: true,
  },
});
