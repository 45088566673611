import React, { memo, useEffect, useState } from 'react';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useFedopsLogger,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { Button } from 'wix-ui-tpa/Button';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';
import { classes, st } from './Widget.st.css';

export default memo<WidgetProps>(() => {
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const { t } = useTranslation();

  const additionalInfo = settings.get(settingsParams.additionalInfo);
  const { appDefinitionId, widgetId: componentId, isSSR } = useEnvironment();

  const bi = useBi();
  const fedops = useFedopsLogger();

  useState(() => {
    if (!isSSR) {
      fedops.interactionStarted('ItemsListMount');
    }
  });

  useEffect(() => {
    fedops.interactionEnded('ItemsListMount');
    bi.templateWidgetLoaded({
      appDefinitionId,
      componentId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={st(classes.root, {})} data-hook="ItemsList-wrapper">
      <div className={classes.header}>
        <h2 data-hook="app-title">
          {t('app.widget.welcome', {
            greetingsText: settings.get(settingsParams.greetingsText),
          })}
          {isMobile ? ' in mobile' : ''}!
        </h2>
        <h3>{settings.get(settingsParams.subTitleText)}</h3>
      </div>
      {additionalInfo && <div data-hook="app-info">{additionalInfo}</div>}
      {experiments.enabled('specs.test.ShouldHideButton') ? null : (
        <Button
          data-hook="click-me-button"
          style={{ borderRadius: styles.get(stylesParams.borderRadius) }}
          className={classes.mainButton}
        >
          Click me
        </Button>
      )}
    </div>
  );
});
